// The file contents for the current environment will overwrite these during
// build. The build system defaults to the dev environment which uses
// 'environment.ts', but if you do 'ng build --env=prod' then
// 'environment.prod.ts' will be used instead. The list of which env maps to
// which file can be found in '.angular-cli.json'.

export const environment = {
  production: false,
  baseAPIUrl: 'https://localhost:4201',
  dossier: '/api/Dossier/',
  neuesDossier: '/api/NeuesDossier/',
  feedback: '/api/Feedback/',
  fieldTypeOperators: './assets/operators.json',
  trefferListe: 'api/Stoffliste',
  supportedUILanguages: [{ Title: 'Deutsch', CultureCode: 'de' }, { Title: 'English', CultureCode: 'en' }],
  supportedUIUserLanguages: [{ Title: 'Deutsch', CultureCode: 'de-DE' }, { Title: 'English', CultureCode: 'en-GB' }],
  defaultUIUserCulture: 'de-DE',
  batchNeueSachverhalte: '/api/batch/NeueSachverhalte',
  batchGesperrteMerkmale: '/api/batch/GesperrteMerkmale',
  batchErfassungen: '/api/batch/Erfassungen',
  batchLoescheDossiers: '/api/batch/LoescheDossiers',
  citation: '/api/Zitat/',
  // User Management
  userManagementUrl: 'https://localhost:4202/User',
  changePasswordUrl: 'https://localhost:4202/Account/ChangePassword',
  manageUserUrl: 'https://localhost:4202/User/Me',
  dataModelEditorUrl: 'http://localhost:4204/',
  // Authentication
  authentication: {
    stsServer: 'https://localhost:4202',
    redirect_url: 'http://localhost:4200/',
    post_logout_redirect_uri: 'http://localhost:4200/',
    log_console_warning_active: false,
    log_console_debug_active: false
  },
  version: '4.5.0'
};
